function initSector() {
  const sectorAll = Array.from(document.querySelectorAll('.sector'))
  if (!sectorAll.length) {
    return
  }
  function drawSector(ctx, lineWidth, size, radius, startAngle, endAngle, color) {
    ctx.beginPath()
    ctx.lineWidth = lineWidth
    ctx.arc(size, size, radius, startAngle, endAngle, true)
    ctx.strokeStyle = color
    ctx.stroke()
  }

  function getRadians(degrees) {
    return (Math.PI / 180) * degrees
  }

  function animateSector(percentCurrent, percentFinish, ctx, lineWidth, size, radius, color, canvas) {
    if (percentCurrent + 1 > percentFinish) {
      return
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    drawSector(ctx, lineWidth, size, radius, 0, Math.PI * 2, '#e7ebee')

    const startAngle = -Math.PI / 2
    const endAngle = -Math.PI / 2 - getRadians((percentCurrent + 1) * 3.6)

    drawSector(ctx, lineWidth, size, radius, startAngle, endAngle, color)

    setTimeout(() => {
      animateSector(percentCurrent + 1, percentFinish, ctx, lineWidth, size, radius, color, canvas)
    }, 10)
  }

  function drawSectorAll(sector) {
    const canvas = sector.querySelector('.sector__cnvs')
    if (!canvas) {
      return
    }
    if (canvas.getContext) {
      const ctx = canvas.getContext('2d')

      const size = sector.offsetWidth / 2
      const lineWidth = size / 4.5
      const radius = size - lineWidth / 2
      const percentFinish = parseInt(sector.dataset.percent, 10)
      const color = sector.dataset.color || '#29cc5f'
      drawSector(ctx, lineWidth, size, radius, 0, Math.PI * 2, '#e7ebee')
      animateSector(0, percentFinish, ctx, lineWidth, size, radius, color, canvas)
    }
  }
  sectorAll.forEach(sector => drawSectorAll(sector))
}

export default initSector
