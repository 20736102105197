import wait from 'images/placeholders/waiting-generic.png'
import notwait from 'images/placeholders/not_available-generic.png'


const uploader = () => {
  $('#fine-uploader-manual-trigger').fineUploader({
    request: {
      endpoint: '/user_assets',
      params: {
        token: $('#fine-uploader-manual-trigger').data('token'),
      },
    },
    deleteFile: {
      enabled: true,
      endpoint: '/user_assets',
    },
    template: 'qq-template-manual-trigger',
    thumbnails: {
      placeholders: {
        waitingPath: wait,
        notAvailablePath: notwait,
      },
    },
    autoUpload: true,
    validation: {
      allowedExtensions: ['doc', 'docx', 'pdf', 'txt', 'jpg', 'png', 'gif', 'tiff', 'jpeg'],
      sizeLimit: 5000000,
      itemLimit: 8,
    },
    messages: {
      typeError: 'У файла {file} неверный тип. Допустимые типы файлов: {extensions}.',
      sizeError: 'Размер {file} превышает допустимый, максимальный размер файла {sizeLimit}.',
      tooManyItemsError:
        'Вы выбрали слишком много файлов ({netItems}). Максимальное количество загружаемых файлов {itemLimit}.',
    },
    // chunking: {
    //   enabled: true,
    //   mandatory: true,
    //   success: {
    //     endpoint: "/upload/finish"
    //   }
    // }
  })
}

document.addEventListener('turbolinks:load', function() {


  if ($('#fine-uploader-manual-trigger').length) {
    uploader()

    let titleStory = document.querySelector('.titleStory')
    let btnSend = document.querySelector('#trigger-upload')

    if (titleStory) {
      titleStory.addEventListener('input', (e) => {

        if (e.target.value.length > 0) {
          btnSend.classList.remove('b-gray')
          btnSend.classList.add('b-white')
        } else {
          btnSend.classList.remove('b-white')
          btnSend.classList.add('b-gray')
        }
      })
    }
  }
})

document.addEventListener('turbolinks:before-cache', function() {
  if ($('#fine-uploader-manual-trigger').length) {
    let $uploader = $('#fine-uploader-manual-trigger')
    $uploader.fineUploader('reset')
    $uploader.empty()
  }
})