import viodejs from 'video.js';
require('videojs-youtube/dist/Youtube.js');
import 'video.js/dist/video-js.css';

const initAdfoxVideo = () => {
  const $adfox = $('.js-adfox');
  if ($adfox && $adfox.length) {
    const $playerElement = $adfox.find('#youtube-player');
    const $adfoxLayer = $adfox.find('.embed-responsive__layer');
    const player = viodejs($playerElement.get(0), {});

    $adfoxLayer.on('click.adfox', function() {
      $adfoxLayer.off('click.adfox');
      const adFoxParameters = {
        ownerId: '694444',
        params: {
          p1: 'cvlsv',
          p2: 'hukh',
        },
      }

      window.ya.videoAd.play({
        // Видеоэлемент
        videoSlot: $adfox.find('.embed-responsive__adfox').get(0),
        // Элемент для показа контролов. Элемент не должен быть статически позиционирован.
        // Можно использовать родительский элемент видео.
        slot: $adfox.find('.embed-responsive__layer').get(0),
        // Конфиг загрузки и конфиг проигрывания рекламы.
        config: {
          adFoxParameters,
        },
      }).finally(() => {
        $adfoxLayer.remove();
        player.play();
      });
    })
  }
}

export default initAdfoxVideo
