function initTranslate() {
  const translateArrow = $('.translate-arrow')
  if (!translateArrow.length) {
    return
  }
  const translateWrap = translateArrow.closest('.js-translate-wrap')
  const slider = translateWrap.find('.responsive-slider')

  const CLASS_ARROW_LEFT = 'translate-arrow__left'
  const CLASS_ARROW_RIGHT = 'translate-arrow__right'
  const CLASS_ARROW_DISABLED = 'translate-arrow__disabled'

  if (slider.slick('getSlick').slideCount < 5) {
    return
  }

  translateArrow.show()

  translateArrow.on('click', (e) => {
    e.preventDefault()
    const $target = $(e.target)

    if ($target.hasClass(CLASS_ARROW_DISABLED)) {
      return
    }
    if ($target.hasClass(CLASS_ARROW_LEFT)) {
      slider.slick('slickPrev')
    }
    if ($target.hasClass(CLASS_ARROW_RIGHT)) {
      slider.slick('slickNext')
    }
  })
}

export default initTranslate
