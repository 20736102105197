function initYaCounter() {
  const $cardInfo = $('.card-info')

  function reachGoal() {
    window.yaCounter46857111.reachGoal($cardInfo.data('target'))
  }

  if ($cardInfo.length && $cardInfo.data('target')) {
    try {
      if (window.yaCounter46857111) {
        reachGoal()
      } else {
        $(window).on('yaMetrikaLoad', () => {
          reachGoal()
        })
      }
    } catch (e) {
      console.error(e.message)
    }
  }

  const $yaCounterHunter = $('.js-ya-counter-hunter')
  if ($yaCounterHunter.length) {
    $yaCounterHunter.on('click', () => {
      try {
        if (window.yaCounter46857111) {
          window.yaCounter46857111.reachGoal('registration')
        }
      } catch (e) {
        console.error(e.message)
      }
    })
  }
}

export default initYaCounter
