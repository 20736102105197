const comments = () => {
  const links = document.querySelectorAll('.comment-link_jspopover')
  const linksArray = Array.prototype.slice.call(links)

  linksArray.forEach(element => {
    //$('.comment-link_gray').mouseover((element) => {
    element.addEventListener('mouseover', (element) => {
      element.preventDefault()
      const val = element.target.dataset.id
      const parent = element.target.parentNode

      if (parent.querySelector('.commentBox')) {
        parent.querySelector('.commentBox').remove()
      }

      fetch(`/api/v1/comments/${val}`).then(function(response) {
        response.json().then(function(data) {
          const commentBox = document.createElement('div')
          commentBox.classList.add('commentBox')

          const commentImage = document.createElement('img')
          commentImage.setAttribute('src', data.avatar)
          commentImage.classList.add('commentBox__img')

          const commentWrapInfo = document.createElement('div')

          const commetntLinkName = document.createElement('a')
          commetntLinkName.innerHTML = data.name
          commetntLinkName.setAttribute('href', data.url_to_profile)
          commetntLinkName.classList.add('commentBox__link')

          const commentTimeAgo = document.createElement('div')
          commentTimeAgo.classList.add('commentBox__time')
          const timeIcon = document.createElement('i')
          timeIcon.classList.add('icon', 'icon-time')
          const timeTxt = document.createElement('span')
          timeTxt.innerHTML = data.time_ago

          commentTimeAgo.appendChild(timeIcon)
          commentTimeAgo.appendChild(timeTxt)

          const commentTxt = document.createElement('p')
          commentTxt.classList.add('commentBox__txt')
          commentTxt.innerHTML = data.body

          commentWrapInfo.appendChild(commetntLinkName)
          commentWrapInfo.appendChild(commentTimeAgo)
          commentWrapInfo.appendChild(commentTxt)

          commentBox.appendChild(commentImage)
          commentBox.appendChild(commentWrapInfo)

          parent.appendChild(commentBox)

          commentBox.addEventListener('mouseover', function() {
            commentBox.style.display = 'flex'
            event.stopPropagation()
          })

          const closebc = () => {
            commentBox.style.display = 'none'
          }
          document.addEventListener('mouseover', closebc)

        })
      })
    })
  })
}

export default comments
