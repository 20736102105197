class TvShowForm {
  constructor() {
    $('.checkbox__input.js_checkbox').on('change', function(e) {
      if ($(this).prop('checked') === true) {
        $("#q_online").val('true');
      }
      if ($(this).prop('checked') === false) {
        $("#q_online").val('false');
      }
      return $('#tv_show_form').submit();
    });
    $('.btn-group__item').on('click', function(e) {
      $('.btn-group__item').removeClass('active');
      $(this).addClass('active');
      $("#q_theme").val($(this).data('theme'));
      return $('#tv_show_form').submit();
    });
    $('.filter-header__select').on('change', function(e) {
      $("#q_theme").val($(this).val());
      if ($('#tv_show_form').length) {
        $('#tv_show_form').submit();
      }
      if ($('#video_form').length) {
        $('#video_form').submit();
      }
      if ($('#articles_form').length) {
        $('#articles_form').submit();
      }
      if ($('#story_form').length) {
        $('#story_form').submit();
      }
      if ($('#profiles_form').length) {
        return $('#profiles_form').submit();
      }
    });
  }
}

export default TvShowForm;