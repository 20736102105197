class VideosForm {
  constructor() {
    $('.btn-group__item').on('click', function(e) {
      $('.btn-group__item').removeClass('active');
      $(this).addClass('active');
      $("#q_theme").val($(this).data('theme'));
      return $('#video_form').submit();
    });
  }
}

export default VideosForm;