import toastr from 'toastr';
class Comments {
  constructor() {
    $(document).on("click", ".comment-link-edit-js", function(event) {
      $('.comment_message-js').removeClass('hidden');
      $('.comment__info').removeClass('hidden');
      $('.comment-btn-group').removeClass('hidden');
      $('.comment-form-edit-js').addClass('hidden');

      $(this).closest('.comment').find('.comment_message-js').addClass('hidden');
      $(this).closest('.comment').find('.comment__info').addClass('hidden');
      $(this).closest('.comment').find('.comment-btn-group').addClass('hidden');
      $(this).closest('.comment').find('.comment-form-edit-js').removeClass('hidden');
      return event.preventDefault();
    });

    $(document).on("click", ".comment-link-reset-js", function(event) {
      $(this).closest('.comment').find('.comment_message-js').removeClass('hidden');
      $(this).closest('.comment').find('.comment__info').removeClass('hidden');
      $(this).closest('.comment').find('.comment-btn-group').removeClass('hidden');
      $(this).closest('.comment').find('.comment-form-edit-js').addClass('hidden');
      return event.preventDefault();
    });


    $('body').on("click", '.comment-link-destroy-js', function(event) {
      if ($(this).hasClass('comment-link_gray')) {
        $(this).removeClass('comment-link_gray');
        $(this).closest('.comment').find('.comment__message').addClass('comment__message_delete');
        $(this).closest('.comment').find('.comment-link-edit-js').addClass('hidden');
        $(this).before('<span class="comment__message-js">Комментарий удалён</span>');
        $(this).text('Востановить');
      } else {
        $(this).closest('.comment').find('.comment__message').removeClass('comment__message_delete');
        $(this).addClass('comment-link_gray');
        $(this).closest('.comment').find('.comment-link-edit-js').removeClass('hidden');
        $(this).closest('.comment').find('.comment__message-js').remove();
        $(this).text('Удалить');
      }
      return event.preventDefault();
    });

    $('body').on("click", '.comment-link-reply-js ', function(event) {
      let target;
      if ((location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')) && (location.hostname === this.hostname)) {
        target = $(this.hash);
        target = target.length ? target : $(`[name=${this.hash.slice(1)}]`);
        if (target.length) {
          $('html, body').animate({ scrollTop: target.offset().top }, 1500);
        }
      }
      const parent_id = $(this).closest('.comment').data('parent_id');
      const user_name = $(this).closest('.comment').data('user_name');
      event.preventDefault();
      $("#comment_parent_id").val(parent_id);
      const comment_form_answer = target.find('.comment-form__answer');
      comment_form_answer.removeClass('hidden');
      const link_to_reply = comment_form_answer.find('.comment-link_gray');
      link_to_reply.html(user_name);
      return link_to_reply.attr("href", `#comment_${parent_id}`);
    });

    $('body').on("click", '.comment-abort-reply-js ', function(event) {
      $("#comment_parent_id").val("");
      $(this).closest('.comment-form-js').find('.comment-form__answer').addClass('hidden');
      return event.preventDefault();
    });


    $('body').on('click', '.comment-link-spam-js', function() {
      $(".comment-link-spam-js").on("ajax:success", function(event) {
        $(this).remove();
        return toastr.success("Благодарим за обращение. В ближайшее время мы ознакомимся с комментарием и примем соответствующие меры");
      });
      return $(".comment-link-spam-js").on("ajax:error", function(event) {
        return toastr.error("Что-то пошло не так! Попробуйте повторить позже.");
      });
    });
  }
}

export default Comments;