const multiselectTags = (data, classMultiselect, qAttribute, id) => {
  /* Элементы DOM куда вставляем теги и карточки */
  const multiselect = document.querySelector(classMultiselect)
  if (!multiselect) {
    return
  }
  const cardList = multiselect.querySelector('.multiselect-card-list')
  const tagList = multiselect.querySelector('.multiselect-tag-list')
  const originalSelect = multiselect.querySelector('.multiselect-hidden-input-box')
  const inputMultiselect = multiselect.querySelector('.multiselect__input')

  const CLASS_LIST_ITEM = ('multiselect-tag-list__item')
  const CLASS_LIST_ITEM_SELECTED = ('multiselect-card-list__item2_selected')
  const CLASS_MULTI_SELECT = ('multiselect')
  const CLASS_MULTI_SELECT_CLOSE = ('multiselect_close')
  const CLASS_MULTI_INPUT = ('multiselect__input')

  /* Список скрытых инпутов */
  const inputHiddenList = multiselect.querySelectorAll('.multiselect-input-hidden')
  const tagData = Array.prototype.slice.call(inputHiddenList)

  /* Массив карточек */
  const cardsData = data

  for (let i = 0; i < cardsData.length; i++) {
    cardsData[i].id = `${id}${i}`
  }

  /* Новый массив тегов на основе инпутов */
  function filterTagData(data) {
    return data.map(item => {
      let val = String(item.value.toLowerCase())
      return cardsData.filter(item => {
        return String(item.name.toLowerCase()) === val
      })
    })
  }

  let filterTagDataList = filterTagData(tagData)

  /* Удаляем изначальные инпуты */
  inputHiddenList.forEach(i => i.remove())

  /* Отрисовываем в DOM новые input */
  function renderStartInput(filterTagDataList) {
    filterTagDataList.forEach(item => {
      const input = document.createElement('input')
      input.value = item[0].name
      input.id = item[0].id
      input.setAttribute('name', qAttribute)
      input.classList.add('multiselect-input-hidden')
      originalSelect.appendChild(input)
    })

    if (filterTagDataList.length && !inputMultiselect.classList.contains('hidden')) {
      inputMultiselect.classList.add('hidden')
      tagList.innerHTML = `<li class="multiselect-tag-list__result">Выбрано: ${tagData.length}</li>`
    }
  }

  renderStartInput(filterTagDataList)

  /* Актуальный массив карточек */
  function initialcardListActual(data) {
    if (inputMultiselect.value) {
      return InputFilterData(inputMultiselect.value, initialcardListActual2(data))
    } else {
      return initialcardListActual2(data)
    }
  }

  function initialcardListActual2(data) {
    const tagData = data.map(item => item.value)
    return cardsData.map((item) => {
      const itemNew = item
      itemNew.selected = tagData.indexOf(item.name) !== -1
      return itemNew
    })
  }

  const cardListActual = initialcardListActual(tagData)

  /* Рендер массива карточек в список */
  function renderCardList(cardsData) {
    cardsData.forEach(function(item) {
      let cardsItem = document.createElement('li')
      let tag = document.createElement('span')

      /* вставляем tag */
      tag.innerHTML = item.name
      cardsItem.classList.add('multiselect-card-list__item2')
      if (item.selected) {
        tag.classList.add('multiselect-card-list__item-tag_selected')
        cardsItem.classList.add('multiselect-card-list__item2_selected')
      }
      cardsItem.appendChild(tag)

      /* добавляем атрибут и класс */
      cardsItem.setAttribute('id', item.id)
      cardsItem.classList.add('multiselect-card-list__item')
      cardsItem.classList.add('multiselect-card-list__item2')
      cardList.appendChild(cardsItem)

      /* навешиваем событие клика каждому item */
      let allItems = cardList.querySelectorAll('.multiselect-card-list__item')
      let allItemsArray = Array.prototype.slice.call(allItems)
      allItemsArray.forEach(function(item) {
        item.addEventListener('click', sendTitle)
      })
    })
  }

  /* начальная отрисовка всех карточек с актуальной длинной массива карточек */
  renderCardList(cardListActual)

  /* функция клика по карточке */
  function sendTitle(e) {
    const inputHiddenList = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagData = Array.prototype.slice.call(inputHiddenList)

    /* чистим список dom элемента списков тегов и карточек */
    tagList.innerHTML = ''
    cardList.innerHTML = ''

    const target = e.target.closest('.multiselect-card-list__item')

    if (target.classList.contains(CLASS_LIST_ITEM_SELECTED)) {
      if (target.id) {
        let delInput = document.getElementById(target.id)
        delInput.remove()
      }
    } else {
      /* создаём новый input */
      let newInput = document.createElement('input')
      newInput.classList.add('multiselect-input-hidden')

      const str = target.firstChild.textContent
      newInput.value = str[0] + str.slice(1)
      newInput.id = target.id

      newInput.setAttribute('name', qAttribute)
      originalSelect.appendChild(newInput)
    }

    /* Актуальный массив инпутов */
    const inputHiddenListUpdate = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagDataUpdate = Array.prototype.slice.call(inputHiddenListUpdate)

    /* Актуальный список карточек */
    const cardListActual = initialcardListActual(tagDataUpdate)
    renderCardList(cardListActual)

    /* Актуальный список тегов */
    let filterTagDataList = filterTagData(tagDataUpdate)

    // Обработчик клика на все теги
    let allTags = tagList.querySelectorAll('.multiselect-tag-list__item')
    let allTagsArray = Array.prototype.slice.call(allTags)
    allTagsArray.forEach(function(item) {
      item.addEventListener('click', clickTag)
    })

    inputChange(filterTagDataList)
    inputMultiselect.focus()
  }

  // Функция клика по тегу
  function clickTag(e) {
    tagList.innerHTML = ''
    cardList.innerHTML = ''
    e.stopPropagation()

    /* удаление input */
    if (e.target.id) {
      let delInput = document.getElementById(e.target.id)
      delInput.remove()
    }

    /* Актуальный массив инпутов */
    const inputHiddenListUpdate = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagDataUpdate = Array.prototype.slice.call(inputHiddenListUpdate)

    /* Актуальный список карточек */
    const cardListActual = initialcardListActual(tagDataUpdate)
    renderCardList(cardListActual)

    /* Актуальный список тегов */
    let filterTagDataList = filterTagData(tagDataUpdate)
    inputChange(filterTagDataList)
  }

  /* Навешиваем клик на кнопку открытия мультиселекта */
  const multiselectBtn = multiselect.querySelector('.multiselect__arrow')
  const multiselectWrap = multiselect.querySelector('.multiselect-wrap')

  function hideMultiSelector(multi) {
    const CardList = multi.querySelector('.multiselect-card-list')

    if (!CardList.classList.contains('hidden')) {
      multi.querySelector('.multiselect__arrow').classList.remove('open')
      tagList.innerHTML = `<li class="multiselect-tag-list__result">Выбрано: ${tagData.length}</li>`
      CardList.classList.add('hidden')
    }

    const inputMulti = multi.querySelector('.' + CLASS_MULTI_INPUT)
    if (multi.querySelector('.' + CLASS_LIST_ITEM)) {
      inputMulti.classList.add('hidden')
      tagList.innerHTML = `<li class="multiselect-tag-list__result">Выбрано: ${tagData.length}</li>`
    } else {
      inputMulti.classList.remove('small')
    }
  }

  function hideOtherMultiSelectors() {
    const allMultiSelector = Array.from(document.querySelectorAll('.' + CLASS_MULTI_SELECT))

    allMultiSelector.forEach(multi => {
      multi.classList.add(CLASS_MULTI_SELECT_CLOSE)
    })
    multiselect.classList.remove(CLASS_MULTI_SELECT_CLOSE)
    const allMultiSelectorToClose = Array.from(document.querySelectorAll('.' + CLASS_MULTI_SELECT_CLOSE))
    allMultiSelectorToClose.forEach(multi => hideMultiSelector(multi))
  }

  function toggleMultiselect() {
    if (multiselectBtn.classList.contains('open')) {
      multiselectBtn.classList.remove('open')
    } else {
      multiselectBtn.classList.add('open')
      tagList.innerHTML = ''
    }
    cardList.classList.toggle('hidden')
    inputMultiselect.classList.remove('hidden')

    inputMultiselect.focus()
  }

  multiselectBtn.addEventListener('click', (event) => {
    event.stopPropagation()
    toggleMultiselect()
  })

  multiselectWrap.addEventListener('click', (event) => {
    event.stopPropagation()
    hideOtherMultiSelectors()
    toggleMultiselect()
  })

  /* клик по поповеру */
  cardList.addEventListener('click', function(event) {
    event.stopPropagation()
  })

  /* клик вне области поповера */
  function closebc() {
    cardList.classList.add('hidden')
    multiselectBtn.classList.remove('open')
    const inputHiddenList = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagData = Array.prototype.slice.call(inputHiddenList)
    if (tagData.length) {
      tagList.innerHTML = `<li class="multiselect-tag-list__result">Выбрано: ${tagData.length}</li>`
      inputMultiselect.classList.add('hidden')
    }
  }

  function isListItem() {
    return multiselect.querySelector('.' + CLASS_LIST_ITEM)
  }

  document.addEventListener('click', closebc)

  /* Навешиваем focus на инпут */
  const inputChange = filterTagDataList => {
    if (filterTagDataList.length > 0 || !inputMultiselect.classList.contains('small')) {
      inputMultiselect.classList.add('small')
      inputMultiselect.focus()
    } else {
      inputMultiselect.classList.remove('small')
    }

    if (!filterTagDataList.length) {
      inputMultiselect.classList.remove('hidden')
    }
  }

  /* Событие ввода на input */
  inputMultiselect.addEventListener('input', function(e) {
    const inputHiddenListUpdate = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagDataUpdate = Array.prototype.slice.call(inputHiddenListUpdate)
    cardList.innerHTML = ''
    const cardListActual = initialcardListActual(tagDataUpdate)
    renderCardList(cardListActual)
  })

  /* Фильтрация массива при вводе в input */
  function InputFilterData(val, data) {
    return data.filter(i => (~i.name.toLowerCase().indexOf(val.toLowerCase())))
  }
}

export default multiselectTags
