class Video {
  constructor() {
    this.youtube()
  }
  
  initYT() {
    function onYouTubeIframeAPIReadyDecorator(f, self) {
      return function() {
        self.youtube()
        if (f) {
          f.apply(self)
        }
      }
    }
    const self = this
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReadyDecorator(window.onYouTubeIframeAPIReady, self)
  }
  youtube() {
    const timeCode = $(".time_code")
    if (!timeCode.length) {
      return
    }
    if ((typeof YT === 'undefined') || !YT || !YT.Player) {
      this.initYT();
      return
    }
    const player = new YT.Player('player_yt_video', {});
    return $('.time_code').on("click", function(event) {
      event.preventDefault();
      const seconds = $(this).data('time_code');
      return player.seekTo(seconds, true);
    });
  }
}
export default Video;