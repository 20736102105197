function addScroll() {
const $eventTopBtn = $('.js-event-top__btn')
const $forWhom = $('#for-whom')
  if ($eventTopBtn.length && $forWhom.length) {
    $eventTopBtn.on('click', () => {
      $('html,body').animate({ scrollTop: $forWhom.offset().top - 30 }, 1000)
    })
  }
}

function initCup() {
  addScroll()
}

export default initCup
