const initYmaps = () => {
  if (!document.querySelector('.ymap')) {
    return
  }

  const wf = document.createElement('script')
    const s = document.scripts[0]
  wf.onload = function () {
    ymaps.ready(init)
  }
  wf.src = 'https://api-maps.yandex.ru/2.1/?apikey=85ddb2bf-95fc-4c06-a010-61416d0912b6&lang=ru_RU'
  wf.async = true
  s.parentNode.insertBefore(wf, s)

  function init() {
    const ymapEl = document.querySelector('.ymap')

    if (!ymapEl.dataset || !ymapEl.dataset.lat || !ymapEl.dataset.lng) {
      throw Error('Нет данных по координатам')
    }
    const center = [ymapEl.dataset.lat, ymapEl.dataset.lng]
    const content = [ymapEl.dataset.content]
    const zoom = ymapEl.dataset.zoom || 17

      const myMap = new ymaps.Map(ymapEl, {
          center,
          zoom,
          controls: ['zoomControl'],
        }, {
          searchControlProvider: 'yandex#search',
        })

        const myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
          hintContent: content,
          balloonContent: content,
        })

      myMap.geoObjects
        .add(myPlacemark)
    }
}

export default initYmaps
