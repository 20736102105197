<template>
  <component
    :is="data.component"
    v-bind="data.props"
  />
</template>

<script>
  const RatingDiscussions = () => import('./components/RatingDiscussions.vue')
  const RatingHistory = () => import('./components/RatingHistory.vue')
  const FormDiscussionsCreate = () => import('./components/FormDiscussionsCreate.vue')
  const FormDiscussionsEdit = () => import('./components/FormDiscussionsEdit.vue')
  const FilterWrap = () => import('./components/FilterWrap.vue')

  export default {
    name: 'CompWrapper',
    components: {
      RatingDiscussions,
      RatingHistory,
      FormDiscussionsCreate,
      FormDiscussionsEdit,
      FilterWrap,
    },
    data() {
      return {
        data: {},
      }
    },
    created() {
      this.data = JSON.parse(this.$attrs.data)
    },
  }
</script>
