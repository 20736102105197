import multiselectAuthor from './multiselectAuthor'
import multiselectVideo from './multiselectVideo'
import multiselectTags from './multiselectTags'

const multiselectsInit = () => {

  const formStory = document.getElementById('story_form')
  const formArticles = document.getElementById('articles_form')
  const formVideo = document.getElementById('video_form')
  const formTvShow = document.getElementById('tv_show_form')
  const formDiscussions = document.getElementById('theme_form')


  if (formStory !== null) {
    const classMultiselect = '.multiselect-story-js'

    const classMultiselectTopic = '.multiselect-story-topic-js'
    const q_Topic = 'q_tag[]'
    const id_Topic = 'idTopic'

    const classMultiselectStoryTag = '.multiselect-story-tag-js'
    const q_Trophies = 'q_trophies[]'
    const id_Trophies = 'idTrophies'

    fetch('/api/v1/stories').then(function(response) {
      response.json().then(function(data) {
        multiselectAuthor(data.users, classMultiselect)
        multiselectTags(data.topic_tag, classMultiselectTopic, q_Topic, id_Topic)
        multiselectTags(data.tags_trophy, classMultiselectStoryTag, q_Trophies, id_Trophies)
      })
    })
  }

  if (formDiscussions !== null) {
    const classMultiselectTopic = '.multiselect-discussions-topic-js'
    const q_Topic = 'q_tag[]'
    const id_Topic = 'idTopic'
    const pathArray = window.location.pathname.split('/');
    const section = pathArray[2]
    const subsection = pathArray[3]
    const theme = pathArray[4]
    fetch(`/api/v1/discussions/${section}/${subsection}/${theme}/tags`).then(function(response) {
      response.json().then(function(data) {
        multiselectTags(data.tags, classMultiselectTopic, q_Topic, id_Topic)
      })
    })
  }

  if (formArticles !== null) {
    const classArticleMultiselect = '.multiselect-article-topic-js'
    const q_ArticleTag = 'q_tag[]'
    const id_ArticleTag = 'idArticleTopics'

    fetch('/api/v1/articles').then(function(response) {
      response.json().then(function(data) {
        multiselectTags(data.topic_tag, classArticleMultiselect, q_ArticleTag, id_ArticleTag)
      })
    })
  }

  if (formVideo !== null) {
    const classMultiselect = '.multiselect-video-js'

    fetch('/api/v1/videos.json').then(function(response) {
      response.json().then(function(data) {
        multiselectVideo(data, classMultiselect)
      })
    })
  }

  if (formTvShow !== null) {
    const classMultiTvShows = '.multiselect-telecasts-js'
    const q_TvShowsTag = 'q_tag[]'
    const id_TvShowsTag = 'idTvShows'

    fetch('/api/v1/tv_shows.json').then(function(response) {
      response.json().then(function(data) {
        multiselectTags(data.tags, classMultiTvShows, q_TvShowsTag, id_TvShowsTag)
      })
    })
  }

}

export default multiselectsInit