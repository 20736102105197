const multiselectVideo = (data, classMultiselect) => {
  /* Элементы DOM куда вставляем теги и карточки */
  const multiselect = document.querySelector(classMultiselect)
  const cardList = multiselect.querySelector('.multiselect-card-list')
  const tagList = multiselect.querySelector('.multiselect-tag-list')
  const originalSelect = multiselect.querySelector('.multiselect-hidden-input-box')
  const inputMultiselect = multiselect.querySelector('.multiselect__input')

  const CLASS_LIST_ITEM = ('multiselect-tag-list__item')
  const CLASS_MULTI_SELECT = ('multiselect')
  const CLASS_MULTI_SELECT_CLOSE = ('multiselect_close')
  const CLASS_MULTI_INPUT = ('multiselect__input')

  /* Список скрытых инпутов */
  const inputHiddenList = multiselect.querySelectorAll('.multiselect-input-hidden')
  const tagData = Array.prototype.slice.call(inputHiddenList)

  /* Массив карточек */
  const cardsData = data.tv_shows

  for (let i = 0; i < cardsData.length; i++) {
    cardsData[i].id = `cardVideo${i}`
  }

  /* Новый массив карточек на основе инпутов */
  function filterTagData(data) {
    return data.map(item => {
      var val = item.value
      return cardsData.filter(item => {
        return item.slug === val
      })
    })
  }

  let filterTagDataList = filterTagData(tagData)

  /* Удаляем изначальные инпуты */
  inputHiddenList.forEach(i => i.remove())

  /* Отрисовываем в DOM новые input */
  function renderStartInput(filterTagDataList) {
    filterTagDataList.forEach(item => {
      const input = document.createElement('input')
      input.value = item[0].slug
      input.id = item[0].id
      input.setAttribute('name', 'q_tv_show[]')
      input.classList.add('multiselect-input-hidden')
      input.setAttribute('data-title', item[0].title)
      originalSelect.appendChild(input)
    })

    if (filterTagDataList.length && !inputMultiselect.classList.contains('hidden')) {
      inputMultiselect.classList.add('hidden')
    }
  }

  renderStartInput(filterTagDataList)

  /* Актуальный массив карточек */
  function initialcardListActual(data) {
    const tagData = data.map(item => item.value)
    return cardsData.filter(item => {
      return tagData.indexOf(item.slug) === -1
    })
  }

  const cardListActual = initialcardListActual(tagData)

  /* Рендер массива карточек в список */
  function renderCardList(cardsData) {
    cardsData.forEach(function(item) {
      let cardsItem = document.createElement('li')
      let image = document.createElement('img')
      let title = document.createElement('p')
      //let slug = document.createElement('span');
      const infoWrap = document.createElement('div')

      /* вставляем img */
      image.src = item.image
      cardsItem.appendChild(image)
      /* вставляем title */
      title.innerHTML = item.title
      title.classList.add('multiselect-card-list__item-title')
      infoWrap.appendChild(title)
      /* вставляем tag */
      if (item.tag && item.tag.name) {
        let tag = document.createElement('span')
        tag.innerHTML = item.tag.name
        tag.classList.add('multiselect-card-list__item-tag')
        tag.classList.add(`tag-theme-${item.tag.theme}`)
        infoWrap.appendChild(tag)
      }
      /* вставляем slug в dataset */
      //slug.innerHTML = item.slug;
      //slug.style.display = 'none';
      //infoWrap.appendChild(slug);
      /* вставляем infoWrap */
      infoWrap.classList.add('multiselect-card-list__item-wrap')
      cardsItem.appendChild(infoWrap)
      /* добавляем атрибут и класс */
      cardsItem.setAttribute('id', item.id)
      cardsItem.setAttribute('data-title', item.slug)
      cardsItem.classList.add('multiselect-card-list__item')
      cardList.appendChild(cardsItem)
      /* навешиваем событие клика каждому item */
      let allItems = cardList.querySelectorAll('.multiselect-card-list__item')
      let allItemsArray = Array.prototype.slice.call(allItems)
      allItemsArray.forEach(function(item) {
        item.addEventListener('click', sendTitle)
      })
    })
  }

  /* начальная отрисовка всех карточек с актуальной длинной массива карточек */
  renderCardList(cardListActual)

  /* Рендер массива тегов */
  function renderTagList(tagData) {
    tagList.innerHTML = ''
    /* перебираем массив и создаём dom элемент для каждого элемента массива и добавляем его */
    tagData.forEach(function(item) {
      let tagWrap = document.createElement('li')
      tagWrap.classList.add('multiselect-tag-list__item')
      /* Крестик тега */
      let tagCross = document.createElement('span')
      tagCross.classList.add('multiselect-tag-list__item-btn')
      tagCross.innerHTML = '×'
      /* Заголовок тега */
      let tag = document.createElement('span')
      tag.classList.add('multiselect-tag-list__item-title')
      tag.id = item[0].id
      tag.innerHTML = item[0].title

      tagWrap.appendChild(tag)
      tagWrap.appendChild(tagCross)
      tagList.appendChild(tagWrap)

      // Обработчик клика на все теги
      const allTags = tagList.querySelectorAll('.multiselect-tag-list__item')
      let allTagsArray = Array.prototype.slice.call(allTags)
      allTagsArray.forEach(function(item) {
        item.addEventListener('click', clickTag)
      })
    })
  }

  /* начальная отрисовка всех тегов */
  renderTagList(filterTagDataList)

  // функция клика по карточке
  function sendTitle(e) {
    // создаём новый input
    let newInput = document.createElement('input')
    newInput.classList.add('multiselect-input-hidden')
    newInput.value = e.target.dataset.title
    newInput.setAttribute('data-title', e.target.lastChild.firstChild.innerText)
    newInput.id = e.target.id
    newInput.setAttribute('name', 'q_tv_show[]')
    originalSelect.appendChild(newInput)

    // чистим список dom элемента списков тегов и карточек
    tagList.innerHTML = ''
    cardList.innerHTML = ''

    /* Актуальный массив инпутов */
    const inputHiddenListUpdate = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagDataUpdate = Array.prototype.slice.call(inputHiddenListUpdate)

    /* Актуальный список карточек */
    const cardListActual = initialcardListActual(tagDataUpdate)
    renderCardList(cardListActual)

    /* Актуальный список тегов */
    let filterTagDataList = filterTagData(tagDataUpdate)
    renderTagList(filterTagDataList)

    // Обработчик клика на все теги
    let allTags = tagList.querySelectorAll('.multiselect-tag-list__item')
    let allTagsArray = Array.prototype.slice.call(allTags)
    allTagsArray.forEach(function(item) {
      item.addEventListener('click', clickTag)
    })
    inputChange(filterTagDataList)
    cardList.classList.toggle('hidden')
    multiselectBtn.classList.toggle('open')

    inputMultiselect.focus()
  }

  // Функция клика по тегу
  function clickTag(e) {
    tagList.innerHTML = ''
    cardList.innerHTML = ''
    e.stopPropagation()

    /* удаление input */
    if (e.target.id) {
      let delInput = document.getElementById(e.target.id)
      delInput.remove()
    }

    /* Актуальный массив инпутов */
    const inputHiddenListUpdate = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagDataUpdate = Array.prototype.slice.call(inputHiddenListUpdate)

    /* Актуальный список карточек */
    const cardListActual = initialcardListActual(tagDataUpdate)
    renderCardList(cardListActual)

    /* Актуальный список тегов */
    let filterTagDataList = filterTagData(tagDataUpdate)
    renderTagList(filterTagDataList)
    inputChange(filterTagDataList)
  }

  /* Навешиваем клик на кнопку открытия мультиселекта */
  const multiselectBtn = multiselect.querySelector('.multiselect__arrow')
  const multiselectWrap = multiselect.querySelector('.multiselect-wrap')

  function hideMultiSelector(multi) {
    const CardList = multi.querySelector('.multiselect-card-list')

    if (!CardList.classList.contains('hidden')) {
      multi.querySelector('.multiselect__arrow').classList.remove('open')
      CardList.classList.add('hidden')
    }

    const inputMulti = multi.querySelector('.' + CLASS_MULTI_INPUT)
    if (multi.querySelector('.' + CLASS_LIST_ITEM)) {
      inputMulti.classList.add('hidden')
    } else {
      inputMulti.classList.remove('small')
    }
  }

  function hideOtherMultiSelectors() {
    const allMultiSelector = Array.from(document.querySelectorAll('.' + CLASS_MULTI_SELECT))

    allMultiSelector.forEach(multi => {
      multi.classList.add(CLASS_MULTI_SELECT_CLOSE)
    })
    multiselect.classList.remove(CLASS_MULTI_SELECT_CLOSE)
    const allMultiSelectorToClose = Array.from(document.querySelectorAll('.' + CLASS_MULTI_SELECT_CLOSE))
    allMultiSelectorToClose.forEach(multi => hideMultiSelector(multi))
  }

  function toggleMultiselect() {
    if (multiselectBtn.classList.contains('open')) {
      multiselectBtn.classList.remove('open')
    } else {
      multiselectBtn.classList.add('open')
    }
    cardList.classList.toggle('hidden')
    inputMultiselect.classList.remove('hidden')

    inputMultiselect.focus()
  }

  multiselectBtn.addEventListener('click', (event) => {
    event.stopPropagation()
    toggleMultiselect()
  })

  multiselectWrap.addEventListener('click', event => {
    event.stopPropagation()
    hideOtherMultiSelectors()
    toggleMultiselect()
  })

  /* клик по поповеру */
  cardList.addEventListener('click', function(event) {
    event.stopPropagation()
  })

  /* клик вне области поповера */
  function closebc() {
    cardList.classList.add('hidden')
    multiselectBtn.classList.remove('open')
    if (isListItem()) {
      inputMultiselect.classList.add('hidden')
    } else {
      inputMultiselect.classList.remove('small')
    }
  }

  function isListItem() {
    return multiselect.querySelector('.' + CLASS_LIST_ITEM)
  }

  document.addEventListener('click', closebc)

  /* Навешиваем focus на инпут */
  const inputChange = filterTagDataList => {
    if (filterTagDataList.length > 0 || !inputMultiselect.classList.contains('small')) {
      inputMultiselect.classList.add('small')
      inputMultiselect.focus()
    } else {
      inputMultiselect.classList.remove('small')
    }

    if (!filterTagDataList.length) {
      inputMultiselect.classList.remove('hidden')
    }
  }

  /* Событие ввода на input */
  inputMultiselect.addEventListener('input', function(e) {
    cardList.classList.remove('hidden')
    var val = e.target.value.toLowerCase()
    cardList.innerHTML = ''

    /* Актуальный массив инпутов */
    const inputHiddenListUpdate = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagDataUpdate = Array.prototype.slice.call(inputHiddenListUpdate)

    /* Актуальный список карточек */
    const cardListActual = initialcardListActual(tagDataUpdate)

    renderCardList(InputFilterData(val, cardListActual))
  })

  /* Фильтрация массива при вводе в input */
  function InputFilterData(val, data) {
    return data.filter(i => ~i.title.toLowerCase().indexOf(val))
  }
}

export default multiselectVideo
