function initVideoTrailer() {
  const trailerBtn = $(".trailer-btn")
  if (!trailerBtn.length) {
    return
  }

  function initYT() {
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
  
  if ((typeof YT === 'undefined') || !YT || !YT.Player) {
    initYT();
  }

  function onTrailerBtnClick() {
    if ((typeof YT === 'undefined') || !YT || !YT.Player) {
      setTimeout(() => {
        onTrailerBtnClick()
      }, 100)
      return
    }
    const videoId = $('#yt-player').attr('data-video-id')
    const trailerPoster = $('.trailer-poster')
    const height = trailerPoster.height()
    const width = trailerPoster.width()

    new YT.Player('yt-player', {
      height,
      width,
      videoId,
      events: {
        'onReady': onPlayerReady,
      },
      playerVars: {
        rel: 0,
        modestbranding: 1,
      },
    });

    function onPlayerReady(event) {
      event.target.playVideo();
    }
    trailerPoster.hide()
  }

  trailerBtn.on('click', (e) => {
    e.preventDefault()
    onTrailerBtnClick()
  })
}

export default initVideoTrailer