import 'jquery/dist/jquery'
import './site/font'
import './vendor/jquery.fine-uploader'
import Turbolinks from 'turbolinks'
import Rails from 'rails-ujs'
import Localtime from './vendor/local_time'

import '../packs/_vue'


import './site/headerMenu'
import './site/init'
import './site/anchor'
import './site/common'
import './site/mask'
// import './site/notifications'
import './site/fine-uploader-config'

Rails.start()
Turbolinks.start()
Localtime.start()
