function initPageSelect() {
  const selectSeason = $(".select-season")
  if (!selectSeason.length) {
    return
  }

  selectSeason.on('change', (e) => {
    e.preventDefault()
    const target = e.target
    const value = target.options[target.selectedIndex].value
    if (value) {
      window.location = value
    }
  })
}

export default initPageSelect;