import toastr from 'toastr'
import HTTP from '../../src/api/http-common'
import URL from '../../src/api/url'

const MAX_LENGTH = 200
const ERROR_MSG = 'Выбран слишком большой объем текста. Чтобы сообщить об ошибке, выделите не более 200 символов.'
const SUCCESS_MSG = 'Благодарим за внимательность! Мы проверим указанный вами фрагмент текста на наличие ошибки.'
let errorTxt = null

function showError() {
  toastr.remove()
  toastr.options = {
    positionClass: 'toast-top-right',
    closeButton: true,
  }
  toastr.error(ERROR_MSG)
}

function showSuccess(msg) {
  toastr.remove()
  toastr.options = {
    positionClass: 'toast-top-right',
    closeButton: true,
  }
  toastr.success(msg)
}

function onBtnSubmitClick() {
  const params = {
    typo_error: {
      message: errorTxt,
    },
  }

  HTTP(URL.user_error_message, 'POST', params)
    .then((data) => {
      if (data && data.result) {
        $.magnificPopup.close()
        showSuccess(SUCCESS_MSG)
      }
    })
}


function openPopup() {
  $.magnificPopup.open({
    items: {
      src: `
      <div id="user-error-warning-popup" class="warning-popup">
        <div class="warning-popup__icon-warning"></div>
        <div class="warning-popup__title">Обнаружена ошибка в тексте:</div>
        <div class="warning-popup__text">
          «${errorTxt}»           
        </div>
        <button class="btn b-green warning-popup__btn-submit user-error-warning-popup__btn-submit">Сообщить</button>
        <button class="btn b-white btn-close-popup">Отмена</button>
      </div>`,
    },
    type: 'inline',
  })

  const btnSubmit = document.querySelector('.user-error-warning-popup__btn-submit')
  btnSubmit.addEventListener('click', onBtnSubmitClick )
}


function onDocumentKeyDown(e) {
  if ((e.ctrlKey || e.metaKey) && (e.key === 'Enter')) {
    errorTxt = window.getSelection().toString().trim()
    switch (true) {
      case !errorTxt.length: return
      case errorTxt.length < MAX_LENGTH: openPopup()
            break
      default:
        showError()
    }
  }
}

function initUserErrorMessage() {
  document.addEventListener('keydown', onDocumentKeyDown)
}

export default initUserErrorMessage
