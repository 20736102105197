function mobileMenuOpen() {
  const menuMobileBtn = document.querySelector('.open-menu-mobile')
  const menuMobile = document.querySelector('.menu-mobile')
  const menuMobileList = document.querySelector('.menu-mobile-list')

  menuMobileBtn.addEventListener('click', (e) => {
    e.stopPropagation()
    if (menuMobile.classList.contains('menu-mobile_hidden')) {
      menuMobile.classList.remove('menu-mobile_hidden')

      document.body.style.overflow = 'hidden'
    } else {
      menuMobile.classList.add('menu-mobile_hidden')

      document.body.style.overflow = 'auto'
    }
  })

  menuMobileList.addEventListener('click', function(event) {
    event.stopPropagation()
  })
  menuMobile.addEventListener('click', function(event) {
    event.stopPropagation()
  })

  function closebc() {
    menuMobile.classList.add('menu-mobile_hidden')
  }

  document.addEventListener('click', closebc)
}


document.addEventListener('turbolinks:load', function() {
  return new mobileMenuOpen()
})
