const valForm = (anyForm) => {
  const form = anyForm
  const buttonSubmit = form.querySelector('.inputSubmit')

  const inputNameAll = form.querySelectorAll('.inputName')
  const inputSurName = form.querySelector('.inputSurName')
  const inputMidName = form.querySelector('.inputMidName')
  const inputEmail = form.querySelector('.inputEmail')
  const inputFirstPass = form.querySelector('.inputFirstPass')
  const inputLastPass = form.querySelector('.inputLastPass')
  const inputUserPass = form.querySelector('.inputUserPass')
  const inputTel = form.querySelector('.inputTel')
  const inputText = form.querySelector('.inputText')
  const inputBirthdayAll = form.querySelectorAll('.inputBirthday')
  const inputPlaceAll = form.querySelectorAll('.inputPlace')
  const checkboxPersonalData = form.querySelector('.checkboxPersonalData')
  const checkboxRulesAccept = form.querySelector('.checkboxRulesAccept')
  const inputTeamName = form.querySelector('.inputTeamName')
  const inputCaliber = form.querySelector('.inputCaliber')
  const inputParticipation = form.querySelector('.inputParticipation')

  // Сброс ошибки
  const resetError = (container) => {
    const errorMsg = container.querySelector('.error-message')
    const marker = container.querySelector('.marker')
    container.classList.remove('error')
    container.classList.remove('complete')
    if (errorMsg) {
      errorMsg.remove()
    }
    if (marker) {
      marker.remove()
    }
  }
  // Показ ошибки, создание сообщения
  const showError = (container, errorMessage) => {
    container.classList.add('error')
    container.classList.remove('complete')
    const msgElem = document.createElement('p')
    const msgTitle = document.createElement('span')
    const marker = document.createElement('div')
    marker.classList.add('marker')
    marker.classList.add('markerCross')
    msgElem.className = 'error-message'
    msgTitle.innerHTML = errorMessage
    msgElem.appendChild(msgTitle)
    container.appendChild(msgElem)
    container.appendChild(marker)

    // Событие клика на крестик
    marker.addEventListener('click', (e) => {
      const parent = e.target.parentNode
      const input = parent.getElementsByTagName('input')
      const anchors = [].slice.call(input)
      anchors.forEach((item) => {
        item.value = ''
      })
      resetError(parent)
    })
  }

  // Успешное прохождение проверки
  const complete = (container) => {
    container.classList.remove('error')
    container.classList.add('complete')
    const marker = document.createElement('div')
    marker.className = 'marker'
    container.appendChild(marker)
  }
  // Успешная проверка паролей
  const completePass = () => {
    resetError(inputFirstPass.parentNode)
    resetError(inputLastPass.parentNode)
    complete(inputFirstPass.parentNode)
    complete(inputLastPass.parentNode)
  }

  // Проверка пароля
  const validationPass = (input) => {
    const val = input.value
    const parent = input.parentNode
    resetError(parent)
    if (!val) {
      showError(parent, 'Укажите пароль')
    } else if (val.length < 6) {
      showError(parent, 'Введите не менее 6 символов')
    } else if (val.length >= 32) {
      showError(parent, 'Введите не более 32 символов')
    } else if (inputFirstPass && inputLastPass && inputLastPass.value.length > 0 && inputFirstPass.value !== inputLastPass.value) {
      showError(parent, 'Пароли не совпадают')
    } else if (inputFirstPass && inputLastPass && inputFirstPass.value === inputLastPass.value) {
      completePass()
    }
  }

  // Проверка пользовательского пароля
  const userPass = (input) => {
    const val = input.value
    const parent = input.parentNode
    resetError(parent)
    if (!val) {
      showError(parent, 'Укажите ваш пароль')
    } else if (val.length < 6) {
      showError(parent, 'Введите весь ваш пароль')
    } else if (val.length >= 32) {
      showError(parent, 'Введите не более 32 символов')
    } else {
      complete(parent)
    }
  }

  // Проверка имени
  const validateName = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    if (!val) {
      showError(parent, 'Введите ваше имя')
    } else if (val.length >= 32) {
      showError(parent, 'Введите ваше сокращенное имя')
    } else if (
      /(?:^|[^a-zA-Zа-яА-ЯёЁ])(?:Суперпользователь|Модератор|Админ|Администратор|Редактор)(?![a-zA-Zа-яА-ЯёЁ])/i.test(
        val,
      ) === true
    ) {
      showError(parent, 'Введите ваше настоящее имя')
    } else if (/([0-9a-zA-Z!@#$%^&*"№;:?+=_])/i.test(val) >= 1) {
      showError(parent, 'Только буквы русского алфавита')
    } else if (val.length === 1) {
      showError(parent, 'Введите ваше полное имя')
    } else {
      complete(parent)
      return true
    }
    return false
  }

  // Проверка фамилии
  const validateSurName = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    switch (true) {
      case val.length <= 1:
        showError(parent, 'Введите вашу полную фамилию')
        return false
      case val.length >= 32:
        showError(parent, 'Введите вашу сокращенную фамилию')
        return false
      case /([0-9a-zA-Z!@#$%^&*"№;:?+=_])/i.test(val) >= 1:
        showError(parent, 'Только буквы русского алфавита')
        return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка отчества
  const validateMidName = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    switch (true) {
      case val.length <= 1:
        showError(parent, 'Введите ваше полное отчество')
        return false
      case val.length >= 32:
        showError(parent, 'Введите ваше сокращенное отчество')
        return false
      case /([0-9a-zA-Z!@#$%^&*"№;:?+=_])/i.test(val) >= 1:
        showError(parent, 'Только буквы русского алфавита')
        return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка email
  const validateEmail = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    resetError(parent)
    switch (true) {
      case !val:
        showError(parent, 'Введите ваш email')
        return false
      case /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(val) === false:
        showError(parent, 'Email ведён неверно')
        return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка телефона
  const validateTel = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    switch (true) {
      case !val:
        showError(parent, 'Введите ваш номер телефона')
        return false
      case val.replace(/\D+/g, '').length < 11:
        showError(parent, 'Укажите верный номер телефона')
        return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка текста
  const validateText = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    switch (true) {
      case !val:
        showError(parent, 'Введите ваше сообщение')
        return false
      default:
        complete(parent)
        return true
    }
  }

  const validateCaliber = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    switch (true) {
      case !val:
        showError(parent, 'Введите калибр')
        return false
      default:
        complete(parent)
        return true
    }
  }

  const validateParticipation = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    switch (true) {
      case !val:
        showError(parent, 'Выберите Ваше участие в кубке')
        return false
      default:
        complete(parent)
        return true
    }
  }

  const validateTeamName = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    switch (true) {
      case !val:
        showError(parent, 'Введите название вашей команды')
        return false
      default:
        complete(parent)
        return true
    }
  }

  function checkChildBirth(input, valArr) {
    const dataChild = $(input).data('cup-children')
    const dataCupStart = $(input).data('cup-start')

    if (dataChild && dataCupStart) {
      try {
        const childDataArr = dataChild.split('_')
        const dateFrom = new Date(dataCupStart)
        const dateTo = new Date(dataCupStart)
        const [hours, minutes] = dataCupStart.split(' ')[1].split(':')
        dateFrom.setFullYear(dateFrom.getFullYear() - childDataArr[1])
        dateTo.setFullYear(dateTo.getFullYear() - childDataArr[0])
        const dateInput = new Date(+valArr[2], +valArr[1] - 1, +valArr[0], +hours, +minutes)
        return { dateFrom, dateTo, dateInput }
      } catch (e) {
        console.error(e.message)
      }
    }
    return false
  }

  // Проверка даты рождения
  const validateBirthday = (input) => {
    const val = input.value
    const parent = input.parentNode
    const valArr = val.split('.')
    const { dateFrom = 1, dateTo = 1, dateInput = 1 } = checkChildBirth(input, valArr)

    resetError(parent)
    switch (true) {
      case !val:
        showError(parent, 'Введите вашу дату рождения')
        return false
      case valArr[0] > 31 || valArr[1] > 12 || valArr[2] < 1900 || valArr[2] > 2019 || val.replace(/\D+/g, '').length < 8:
        showError(parent, 'Укажите верную дату рождения')
        return false
      // case dateFrom > dateInput || dateTo < dateInput:
      //   showError(parent, 'Выбрана не верная категория для ребёнка')
      //   return false
      default:
        complete(parent)
        return true
    }
  }

  // Проверка места регистрации
  const validatePlace = (input) => {
    const val = input.value
    const parent = input.parentNode

    resetError(parent)
    switch (true) {
      case !val:
        showError(parent, 'Введите ваш город проживания')
        return false
      case /([a-zA-Z!@#$%^&*"№;:?+=_])/i.test(val) >= 1:
        showError(parent, 'Только цифры и буквы русского алфавита')
        return false
      default:
        complete(parent)
        return true
    }
  }

  // Общая проверка всех инпутов
  const allowSubmit = (allowForm) => {
    const inputArray = [].slice.call(allowForm.querySelectorAll('.form-item'))
    const activateArray = inputArray.filter(inputComplete => inputComplete.classList.contains('complete'))
    if (activateArray.length === inputArray.length) {
      buttonSubmit.classList.remove('disabled')
      buttonSubmit.removeAttribute('disabled')
    } else {
      buttonSubmit.classList.add('disabled')
      buttonSubmit.setAttribute('disabled', 'disabled')
    }
  }

  // Обработчики событий на все инпуты

  function checkInputName(inputName) {
    if (inputName.value) {
      validateName(inputName)
      allowSubmit(anyForm)
    }
    inputName.addEventListener('input', (e) => {
      validateName(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputNameAll.length) {
    Array.from(inputNameAll).forEach(input => checkInputName(input))
  }

  if (inputSurName) {
    if (inputSurName.value) {
      validateSurName(inputSurName)
      allowSubmit(anyForm)
    }
    inputSurName.addEventListener('input', (e) => {
      validateSurName(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputMidName) {
    if (inputMidName.value) {
      validateMidName(inputMidName)
      allowSubmit(anyForm)
    }
    inputMidName.addEventListener('input', (e) => {
      validateMidName(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputEmail) {
    if (inputEmail.value) {
      validateEmail(inputEmail)
      allowSubmit(anyForm)
    }
    inputEmail.addEventListener('blur', (e) => {
      validateEmail(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputFirstPass) {
    if (inputFirstPass.value) {
      validationPass(inputFirstPass)
      allowSubmit(anyForm)
    }
    inputFirstPass.addEventListener('input', (e) => {
      validationPass(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputLastPass) {
    if (inputLastPass.value) {
      validationPass(inputLastPass)
      allowSubmit(anyForm)
    }
    inputLastPass.addEventListener('input', (e) => {
      validationPass(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputUserPass) {
    if (inputUserPass.value) {
      userPass(inputUserPass)
      allowSubmit(anyForm)
    }
    inputUserPass.addEventListener('input', (e) => {
      userPass(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputTel) {
    if (inputTel.value) {
      validateTel(inputTel)
      allowSubmit(anyForm)
    }
    inputTel.addEventListener('blur', (e) => {
      validateTel(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputCaliber) {
    if (inputCaliber.value) {
      validateCaliber(inputCaliber)
      allowSubmit(anyForm)
    }
    inputCaliber.addEventListener('blur', (e) => {
      validateCaliber(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputParticipation) {
    if (inputParticipation.value) {
      validateParticipation(inputParticipation)
      allowSubmit(anyForm)
    }
    inputParticipation.addEventListener('change', (e) => {
      validateParticipation(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputTeamName) {
    inputTeamName.addEventListener('blur', (e) => {
      validateTeamName(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputText) {
    if (inputText.value) {
      validateText(inputText)
      allowSubmit(anyForm)
    }
    inputText.addEventListener('blur', (e) => {
      validateText(e.target)
      allowSubmit(anyForm)
    })
  }

  function checkInputBirthday(inputBirthday) {
    if (inputBirthday.value) {
      validateBirthday(inputBirthday)
      allowSubmit(anyForm)
    }
    inputBirthday.addEventListener('blur', (e) => {
      validateBirthday(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputBirthdayAll.length) {
    Array.from(inputBirthdayAll).forEach(input => checkInputBirthday(input))
  }

  function checkInputPlace(inputPlace) {
    if (inputPlace.value) {
      validatePlace(inputPlace)
      allowSubmit(anyForm)
    }
    inputPlace.addEventListener('input', (e) => {
      validatePlace(e.target)
      allowSubmit(anyForm)
    })
  }

  if (inputPlaceAll.length) {
    Array.from(inputPlaceAll).forEach(input => checkInputPlace(input))
  }

  if (checkboxPersonalData) {
    if (checkboxPersonalData.checked) {
      complete(checkboxPersonalData.closest('.form-item'))
      allowSubmit(anyForm)
    }
    checkboxPersonalData.addEventListener('change', (e) => {
      if (e.target.checked) {
        complete(checkboxPersonalData.closest('.form-item'))
      } else {
        resetError(checkboxPersonalData.closest('.form-item'))
      }
      allowSubmit(anyForm)
    })
  }
  if (checkboxRulesAccept) {
    if (checkboxRulesAccept.checked) {
      complete(checkboxRulesAccept.closest('.form-item'))
      allowSubmit(anyForm)
    }
    checkboxRulesAccept.addEventListener('change', (e) => {
      if (e.target.checked) {
        complete(checkboxRulesAccept.closest('.form-item'))
      } else {
        resetError(checkboxRulesAccept.closest('.form-item'))
      }
      allowSubmit(anyForm)
    })
  }
}

function onWarningPopupBtnClick(form) {
  form.submit()
}

function onEditUserFormSubmit(e) {
  const form = this
  const inputName = form.querySelector('input[name="profile[name]"]')
  const inputSurName = form.querySelector('input[name="profile[last_name]"]')
  const inputSubmit = form.querySelector('.inputSubmit')

  if (inputName.getAttribute('value') === inputName.value && inputSurName.getAttribute('value') === inputSurName.value) {
    return
  }
  e.preventDefault()
  $.magnificPopup.open({
    items: {
     src: $('#profile-change-warning-popup'),
      },
     type: 'inline',
  })

  setTimeout(() => {
    inputSubmit.disabled = false
  }, 100)
  $('.profile-change-warning-popup__btn-submit').one('click', onWarningPopupBtnClick.bind(this, form))
}

const validationFormInit = () => {
  const regForm = document.querySelector('.form-new-user')
  const sessionForm = document.querySelector('.form-auth')
  const editUserForm = document.querySelector('.form-edit-profile')
  const resetForm = document.querySelector('.form-reset')
  const newPasswordForm = document.querySelector('.form-new-password')
  const formUnlock = document.querySelector('.form-unlock')
  const formNomination = document.querySelector('.form-nomination')
  const changePasswordForm = document.querySelector('.change-password-form')
  const formFeedback = document.querySelector('.form-feedback')
  const formCupRegAll = document.querySelectorAll('.form-cup-reg')

  if (regForm) {
    valForm(regForm)
  }
  if (sessionForm) {
    valForm(sessionForm)
  }
  if (editUserForm) {
    editUserForm.addEventListener('submit', onEditUserFormSubmit)
    valForm(editUserForm)
  }
  if (resetForm) {
    valForm(resetForm)
  }
  if (newPasswordForm) {
    valForm(newPasswordForm)
  }
  if (formUnlock) {
    valForm(formUnlock)
  }
  if (formNomination) {
    valForm(formNomination)
  }
  if (changePasswordForm) {
    valForm(changePasswordForm)
  }
  if (formFeedback) {
    valForm(formFeedback)
  }
  if (formCupRegAll) {
    Array.from(formCupRegAll).forEach(form => valForm(form))
  }
}

export default validationFormInit
