import toastr from 'toastr'

export default (urlFrom, method = 'POST', params = []) => {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  headers.append('X-CSRF-TOKEN', csrfToken)

  function showError(msg) {
    toastr.remove()
    toastr.options = {
      positionClass: 'toast-top-right',
      closeButton: true,
    }
    toastr.error(msg)
  }

  const body = (method !== 'GET') ? JSON.stringify(params) : null

  return fetch(urlFrom, {
    method,
    headers,
    body,
  })
    .then(response => response.json())
    .then((data) => {
    if (data.error && data.error.message) {
      showError(data.error.message)
    }
    return data
    })
    .catch((error) => {
      showError('Проблемы на сервере. Пожалуйста, попробуйте позже.')
      console.error(error)
    })
}
