// import toastr from 'toastr'
//
//
// document.addEventListener('turbolinks:load', function () {
//
//   $('.js_edit_profile').on('click', function() {
//       document.addEventListener('turbolinks:load', function() {
//         let profileHref = window.location.href.split('/edit')[0];
//         if (document.body.dataset.jsClass === 'ProfilesEdit') {
//           toastr.remove();
//           toastr.options = {
//             positionClass: 'toast-top-full-width'
//           }
//           toastr.warning(`<div class="notification-panel"><div class= "notification-panel-left"><span>Если вы измените имя, фамилию, фотографию или содержимое раздела "о себе", ваш профиль потеряет статус "Проверен" и будет вновь проверен модератором.</span></div><div class="notification-panel-right"><a href=${profileHref} class="notification-panel__link">Вернуться в профиль</a> <button class="btn_link js-notif-close">Закрыть</button></div></div >`)
//         }
//       })
//   })
// });

function initNotification() {
  $('.js-notif-close').click((e) => {
    e.preventDefault()
    $(e.target).closest('.notification-panel').hide()
  })
}

export default initNotification;

