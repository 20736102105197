function filter() {
  var moreBlock = document.querySelector('.filter-inner')
  var moreBtn = document.querySelector('.filter-more-btn')
  var moreBtnSpan = document.querySelector('.filter-more-btn__txt')

  if (moreBtn) {
    moreBtn.addEventListener('click', () => {
      if (moreBlock.classList.contains('hidden')) {
        moreBlock.classList.remove('hidden')
        moreBtnSpan.innerHTML = 'Свернуть'
        moreBtn.classList.add('open')
      } else {
        moreBlock.classList.add('hidden')
        moreBtnSpan.innerHTML = 'Расширенный поиск'
        moreBtn.classList.remove('open')
      }
    })
  }
}


const filterInit = () => {
  const filterUi = document.querySelector('.filter-ui')

  if (filterUi) {
    filter()
  }
}

export default filterInit
