const goToComment = () => {
  const links = document.querySelectorAll('.comment-link_jspopover')
  const btn = document.querySelector('.add-comments-js')

  if (links) {
    for (let link of links) {
      link.addEventListener('click', (e) => {
        e.preventDefault()
        let linkId = e.target.dataset.id
        let element = document.getElementById(`comment_${linkId}`)
        if (element === null) {
          btn.click()
          $('.add-comments-js').on('ajax:success', function(e) {
            e.preventDefault()
            $('html,body').animate({ scrollTop: $(`#comment_${linkId}`).offset().top }, 1000)
          })
        } else {
          $('html,body').animate({ scrollTop: $(`#comment_${linkId}`).offset().top }, 1000)
        }
      })
    }
  }
}

export default goToComment