import Cropper from 'cropperjs'

const dragNdrop = () => {

  const editPhotoLink = document.querySelector('.edit-photo-link')
  editPhotoLink.addEventListener('click', () => {
    const fileElemInput = document.getElementById('fileElem')
    fileElemInput.addEventListener('change', (e) => {
      handleFiles(e.target.files)
    })
  })

  // ************************ Drag and drop ***************** //
  let dropArea = document.getElementById('drop-area')
    // Prevent default drag behaviors
  ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
    dropArea.addEventListener(eventName, preventDefaults, false)
    document.body.addEventListener(eventName, preventDefaults, false)
  })

  // Highlight drop area when item is dragged over it
  ;['dragenter', 'dragover'].forEach(eventName => {
    dropArea.addEventListener(eventName, highlight, false)
  })

  ;['dragleave', 'drop'].forEach(eventName => {
    dropArea.addEventListener(eventName, unhighlight, false)
  })

  // Handle dropped files
  dropArea.addEventListener('drop', handleDrop, false)

  function preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  function highlight() {
    dropArea.classList.add('highlight')
  }

  function unhighlight() {
    dropArea.classList.remove('highlight')
  }

  function handleDrop(e) {
    var dt = e.dataTransfer
    var files = dt.files
    handleFiles(files)
  }

  // let uploadProgress = []
  // let progressBar = document.getElementById('progress-bar')

  /* function initializeProgress(numFiles) {
    progressBar.value = 0
    uploadProgress = []

    for (let i = numFiles; i > 0; i--) {
      uploadProgress.push(0)
    }
  } */

  // function updateProgress(fileNumber, percent) {
  //   uploadProgress[fileNumber] = percent
  //   let total = uploadProgress.reduce((tot, curr) => tot + curr, 0) / uploadProgress.length
  //   //console.debug('update', fileNumber, percent, total)
  //   progressBar.value = total
  // }

  function handleFiles(files) {
    files = [...files]
    //initializeProgress(files.length)
    //files.forEach(uploadFile)
    files.forEach(previewFile)
  }

  function previewFile(file) {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function() {
      let img = document.createElement('img')
      img.setAttribute('id', 'image')
      img.src = reader.result
      document.getElementById('gallery').innerHTML = ''
      document.getElementById('gallery').appendChild(img)

      const image = document.getElementById('image')
      const btnGetCropped = document.querySelector('#btnGetCropped')
      //const btnClearCropped = document.querySelector('#btnClearCropped');
      const imageForm = document.querySelector('.my-form')
      const inputBase64 = document.querySelector('#getBase64')
      const popoverCloseBtn = document.querySelector('.mfp-close')
      const popupList = document.querySelector('.edit-photo-popup-list')
      const hiddenTxt = document.querySelector('.edit-photo-popup-hidden-txt')
      //const btnGroup = document.querySelector('.btn-group-popup')

      btnGetCropped.classList.remove('hidden')
      //btnGetCropped.classList.remove('b-gray');
      btnGetCropped.classList.add('b-green')
      btnGetCropped.removeAttribute('disabled')

      popupList.classList.add('hidden')
      hiddenTxt.classList.remove('hidden')
      //btnGroup.classList.remove('hidden');


      // btnClearCropped.addEventListener('click', () => {
      //   cropper.destroy();
      //   image.remove();
      //   btnGetCropped.classList.remove('b-green');
      //   btnGetCropped.classList.add('b-gray');
      //   btnGetCropped.setAttribute('disabled', 'disabled');

      //   popupList.classList.remove('hidden');
      //   hiddenTxt.classList.add('hidden');
      //   btnGroup.classList.add('hidden');
      // });

      popoverCloseBtn.addEventListener('click', () => {
        cropper.destroy()
        image.remove()
        btnGetCropped.classList.remove('b-green')
        btnGetCropped.classList.add('b-gray')
        btnGetCropped.setAttribute('disabled', 'disabled')
      })

      btnGetCropped.addEventListener('click', () => {
        //e.preventDefault();
        const canvasImage = cropper.getCroppedCanvas({
          width: 240,
          height: 240,
        })
        inputBase64.value = canvasImage.toDataURL('base64')
        imageForm.submit()
      })
      var minAspectRatio = 0.5
      var maxAspectRatio = 1.5

      var cropper = new Cropper(image, {
        aspectRatio: 1 / 1,
        viewMode: 1,
        ready: function() {
          var cropper = this.cropper
          var containerData = cropper.getContainerData()
          var cropBoxData = cropper.getCropBoxData()
          var aspectRatio = cropBoxData.width / cropBoxData.height
          var newCropBoxWidth

          if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
            newCropBoxWidth = cropBoxData.height * ((minAspectRatio + maxAspectRatio) / 2)

            cropper.setCropBoxData({
              left: (containerData.width - newCropBoxWidth) / 2,
              width: newCropBoxWidth,
            })
          }
        },

        cropmove: function() {
          var cropper = this.cropper
          var cropBoxData = cropper.getCropBoxData()
          var aspectRatio = cropBoxData.width / cropBoxData.height

          if (aspectRatio < minAspectRatio) {
            cropper.setCropBoxData({
              width: cropBoxData.height * minAspectRatio,
            })
          } else if (aspectRatio > maxAspectRatio) {
            cropper.setCropBoxData({
              width: cropBoxData.height * maxAspectRatio,
            })
          }
        },
      })
    }
  }

  // function uploadFile(file, i) {
  //   var url = 'https://api.cloudinary.com/v1_1/joezimim007/image/upload'
  //   var xhr = new XMLHttpRequest()
  //   var formData = new FormData()
  //   xhr.open('POST', url, true)
  //   xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

  //   // Update progress (can be used to show progress indicator)
  //   xhr.upload.addEventListener("progress", function (e) {
  //     updateProgress(i, (e.loaded * 100.0 / e.total) || 100)
  //   })

  //   xhr.addEventListener('readystatechange', function (e) {
  //     if (xhr.readyState == 4 && xhr.status == 200) {
  //       updateProgress(i, 100) // <- Add this
  //     }
  //     else if (xhr.readyState == 4 && xhr.status != 200) {
  //       // Error. Inform the user
  //     }
  //   })

  //   formData.append('upload_preset', 'ujpu6gyk')
  //   formData.append('file', file)
  //   xhr.send(formData)
  // }
}

const dragNdropInit = () => {
  const popupEditPhoto = document.querySelector('.edit-photo-popup')
  if (popupEditPhoto) {
    dragNdrop()
  }
}

export default dragNdropInit

