import './polyfills'
import TvShowForm from './tv_show'
import VideosForm from './videos_form'
import Comments from './comments2'
import FlashNotice from './flash_notice'
import AddFox from './addfox'
import Video  from './video'
import multiselectsInit from './multiselectsInit'
import dragNdropInit from './dragNdropInit'
import popupInit from './popupInit'
import validationFormInit from './validationFormInit'
import filterInit from './filterInit'
import anchor from './anchor'
import comments from './comments.js'
import moreComments from './more_comments.js'
import initSector from './sector'
import initYmaps from './ymaps'
import initSlider from './slider'
import initSocialLikes from './socialLikes'
import initTranslate from './translate'
import initPageSingleSelect from './pageSingeSelect'
import initVideoTrailer from './video-trailer'
import initUserErrorMessage from './userErrorMessage'
import initNotification from './notifications'
import initCup from './cup'
import initYaCounter from './yaCounter'
import initAdfoxVideo from './adfox-video'

window.initSector = initSector

document.addEventListener('turbolinks:load', function() {
  new TvShowForm()
  new VideosForm()
  new Comments()
  new FlashNotice()
  // new AddFox()
  new Video()
  initSocialLikes()
  multiselectsInit()
  dragNdropInit()
  popupInit()
  validationFormInit()
  filterInit()
  anchor()
  comments()
  moreComments()
  initSector()
  initYmaps()
  initSlider()
  initTranslate()
  initPageSingleSelect()
  initVideoTrailer()
  initUserErrorMessage()
  initNotification()
  initCup()
  initYaCounter()
  initAdfoxVideo()
})


