import toastr from 'toastr'

class FlashNotice {
  constructor() {
    toastr.clear()
    toastr.options = {
      'closeButton': true,
      'debug': false,
      'progressBar': false,
      'preventDuplicates': true,
      'positionClass': 'toast-top-right',
      'onclick': null,
      'showDuration': '400',
      'hideDuration': '400',
      'timeOut': 3000,
      'extendedTimeOut': 3000,
      'showEasing': 'swing',
      'hideEasing': 'linear',
      'showMethod': 'fadeIn',
      'hideMethod': 'fadeOut',
      'closeHtml': '<button><i class=\'icon icon-cross\'></i></button>',
    }


    $('.flash_error, .flash_warning, .flash_notice, .flash_alert, .flash_success, .flash_info, .flash_recaptcha_error, .form__server-response-txt').each(function() {
      toastr.clear()
      toastr.remove()
      const $flash = $(this)
      let type = $flash.data('type')
      if (!type) {
        return
      }
      const message = $flash.text()
      if (type === 'notice') {
        type = 'success'
      }
      if (type === 'alert') {
        type = 'error'
      }
      if (type === 'recaptcha_error') {
        type = 'error'
      }
      toastr[type](`<p>${message}</p>`)
      return $(this).remove()
    })
  }
}

export default FlashNotice