const multiselectAuthor = (data, classMultiselect) => {
  /* Элементы DOM куда вставляем теги и карточки */
  const multiselect = document.querySelector(classMultiselect)
  const cardList = multiselect.querySelector('.multiselect-card-list')
  const tagList = multiselect.querySelector('.multiselect-tag-list')
  const originalSelect = multiselect.querySelector('.multiselect-hidden-input-box')
  const inputMultiselect = multiselect.querySelector('.multiselect__input')

  const CLASS_LIST_ITEM = ('multiselect-tag-list__item')
  const CLASS_MULTI_SELECT = ('multiselect')
  const CLASS_MULTI_SELECT_CLOSE = ('multiselect_close')
  const CLASS_MULTI_INPUT = ('multiselect__input')

  /* Список скрытых инпутов */
  const inputHiddenList = multiselect.querySelectorAll('.multiselect-input-hidden')
  const tagData = Array.prototype.slice.call(inputHiddenList)

  /* Массив карточек */
  const cardsData = data

  for (let i = 0; i < cardsData.length; i++) {
    cardsData[i].id = `cardStory${i}`
  }

  /* Новый массив тегов на основе инпутов */
  function filterTagData(tagData) {
    return tagData.map(item => {
      var val = item.value
      return cardsData.filter(item => {
        return item.uid == val
      })
    })
  }

  let filterTagDataList = filterTagData(tagData)

  /* Удаляем изначальные инпуты */
  inputHiddenList.forEach(i => i.remove())

  /* Отрисовываем в DOM новые input */
  function renderStartInput(filterTagDataList) {
    filterTagDataList.forEach(item => {
      const input = document.createElement('input')
      input.value = item[0].uid
      input.id = item[0].id
      input.setAttribute('name', 'q_author[]')
      input.classList.add('multiselect-input-hidden')
      input.setAttribute('data-title', item[0].full_name)
      originalSelect.appendChild(input)
    })

    if (filterTagDataList.length && !inputMultiselect.classList.contains('hidden')) {
      inputMultiselect.classList.add('hidden')
    }
  }

  renderStartInput(filterTagDataList)

  /* Актуальный массив карточек */
  function initialcardListActual(data) {
    const tagData = data.map(item => item.value)
    return cardsData.filter(item => {
      return tagData.indexOf(String(item.uid)) === -1
    })
  }

  const cardListActual = initialcardListActual(tagData)


  /* Функция склонения история-истории-историй */
  function declOfNum(number, titles) {
    let cases = [2, 0, 1, 1, 1, 2]
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
  }

  /* Рендер массива карточек в список */
  function renderCardList(cardsData) {
    cardsData.forEach(function(item) {
      const cardsItem = document.createElement('li')
      const image = document.createElement('img')
      const title = document.createElement('p')
      const storyCount = document.createElement('span')
      const popular = document.createElement('span')
      const row = document.createElement('p')
      const infoWrap = document.createElement('div')
      const star = document.createElement('i')
      const txt = document.createElement('span')

      /* вставляем img */
      image.src = item.avatar
      cardsItem.appendChild(image)
      /* вставляем title */
      title.innerHTML = item.full_name
      title.classList.add('multiselect-card-list__item-title')
      infoWrap.appendChild(title)


      /*Вставляем кол-во историй */
      row.classList.add('multiselect-card-list-row')

      let count = item.story_count
      storyCount.innerHTML = `${count} ${declOfNum(count, ['история', 'истории', 'историй'])}`
      storyCount.classList.add('multiselect-card-list__count')
      row.appendChild(storyCount)
      /*Вставляем популярность */
      txt.innerHTML = 'Популярный автор'
      if (item.popular) {
        star.classList.add('icon')
        star.classList.add('icon-star-small')
        popular.classList.add('multiselect-card-list__popular')
        popular.appendChild(star)
        popular.appendChild(txt)
        row.appendChild(popular)
      }

      infoWrap.appendChild(row)

      /* вставляем infoWrap */
      infoWrap.classList.add('multiselect-card-list__item-wrap')
      cardsItem.appendChild(infoWrap)
      /* добавляем атрибут и класс */
      cardsItem.setAttribute('id', item.id)
      cardsItem.setAttribute('data-title', item.uid)
      cardsItem.classList.add('multiselect-card-list__item')
      cardList.appendChild(cardsItem)
      /* навешиваем событие клика каждому item */
      let allItems = cardList.querySelectorAll('.multiselect-card-list__item')
      let allItemsArray = Array.prototype.slice.call(allItems)
      allItemsArray.forEach(function(item) {
        item.addEventListener('click', sendTitle)
      })
    })
  }

  /* начальная отрисовка всех карточек с актуальной длинной массива карточек */
  renderCardList(cardListActual)

  /* Рендер массива тегов */
  function renderTagList(tagData) {
    tagList.innerHTML = ''
    /* перебираем массив и создаём dom элемент для каждого элемента массива и добавляем его */
    tagData.forEach(function(item) {
      let tagWrap = document.createElement('li')
      tagWrap.classList.add('multiselect-tag-list__item')
      /* Крестик тега */
      let tagCross = document.createElement('span')
      tagCross.classList.add('multiselect-tag-list__item-btn')
      tagCross.innerHTML = '×'
      /* Заголовок тега */
      let tag = document.createElement('span')
      tag.classList.add('multiselect-tag-list__item-title')
      tag.id = item[0].id
      tag.innerHTML = item[0].full_name

      tagWrap.appendChild(tag)
      tagWrap.appendChild(tagCross)
      tagList.appendChild(tagWrap)

      // Обработчик клика на все теги
      const allTags = tagList.querySelectorAll('.multiselect-tag-list__item')
      let allTagsArray = Array.prototype.slice.call(allTags)
      allTagsArray.forEach(function(item) {
        item.addEventListener('click', clickTag)
      })
    })
  }

  /* начальная отрисовка всех тегов */
  renderTagList(filterTagDataList)

  // функция клика по карточке
  function sendTitle(e) {
    const inputHiddenList = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagData = Array.prototype.slice.call(inputHiddenList)

    tagList.innerHTML = ''
    cardList.innerHTML = ''
    // создаём новый input
    if (tagData.length < 1) {
      let newInput = document.createElement('input')
      newInput.classList.add('multiselect-input-hidden')
      newInput.value = e.target.dataset.title
      newInput.setAttribute('data-title', e.target.lastChild.firstChild.innerText)
      newInput.id = e.target.id
      newInput.setAttribute('name', 'q_author[]')
      originalSelect.appendChild(newInput)
    }

    // чистим список dom элемента списков тегов и карточек
    tagList.innerHTML = ''
    cardList.innerHTML = ''

    /* Актуальный массив инпутов */
    const inputHiddenListUpdate = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagDataUpdate = Array.prototype.slice.call(inputHiddenListUpdate)

    /* Актуальный список карточек */
    const cardListActual = initialcardListActual(tagDataUpdate)
    renderCardList(cardListActual)

    /* Актуальный список тегов */
    let filterTagDataList = filterTagData(tagDataUpdate)
    renderTagList(filterTagDataList)

    // Обработчик клика на все теги
    let allTags = tagList.querySelectorAll('.multiselect-tag-list__item')
    let allTagsArray = Array.prototype.slice.call(allTags)
    allTagsArray.forEach(function(item) {
      item.addEventListener('click', clickTag)
    })
    inputChange(filterTagDataList)
    cardList.classList.toggle('hidden')
  }

  // Функция клика по тегу
  function clickTag(e) {
    tagList.innerHTML = ''
    cardList.innerHTML = ''
    e.stopPropagation()
    /* удаление input */

    if (e.target.id) {
      let delInput = document.getElementById(e.target.id)
      delInput.remove()
    }

    /* Актуальный массив инпутов */
    const inputHiddenListUpdate = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagDataUpdate = Array.prototype.slice.call(inputHiddenListUpdate)

    /* Актуальный список карточек */
    const cardListActual = initialcardListActual(tagDataUpdate)
    renderCardList(cardListActual)

    /* Актуальный список тегов */
    let filterTagDataList = filterTagData(tagDataUpdate)
    renderTagList(filterTagDataList)
    inputChange(filterTagDataList)
  }

  /* Навешиваем клик на кнопку открытия мультиселекта */
  const multiselectBtn = multiselect.querySelector('.multiselect__arrow')
  const multiselectWrap = multiselect.querySelector('.multiselect-wrap')

  function hideMultiSelector(multi) {
    const CardList = multi.querySelector('.multiselect-card-list')

    if (!CardList.classList.contains('hidden')) {
      multi.querySelector('.multiselect__arrow').classList.remove('open')
      CardList.classList.add('hidden')
    }

    const inputMulti = multi.querySelector('.' + CLASS_MULTI_INPUT)
    if (multi.querySelector('.' + CLASS_LIST_ITEM)) {
      inputMulti.classList.add('hidden')
    } else {
      inputMulti.classList.remove('small')
    }
  }

  function hideOtherMultiSelectors() {
    const allMultiSelector = Array.from(document.querySelectorAll('.' + CLASS_MULTI_SELECT))

    allMultiSelector.forEach(multi => {
      multi.classList.add(CLASS_MULTI_SELECT_CLOSE)
    })
    multiselect.classList.remove(CLASS_MULTI_SELECT_CLOSE)
    const allMultiSelectorToClose = Array.from(document.querySelectorAll('.' + CLASS_MULTI_SELECT_CLOSE))
    allMultiSelectorToClose.forEach(multi => hideMultiSelector(multi))
  }

  function toggleMultiselect() {
    if (tagData.length === 1) {
      multiselectBtn.classList.remove('open')
      cardList.classList.add('hidden')
    } else {
      multiselectBtn.classList.add('open')
      cardList.classList.remove('hidden')
    }

    inputMultiselect.focus()
  }

  multiselectBtn.addEventListener('click', event => {
    const inputHiddenList = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagData = Array.prototype.slice.call(inputHiddenList)

    event.stopPropagation()
    toggleMultiselect()
  })
  multiselectWrap.addEventListener('click', event => {
    const inputHiddenList = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagData = Array.prototype.slice.call(inputHiddenList)

    event.stopPropagation()
    hideOtherMultiSelectors()
    toggleMultiselect()
  })

  /* клик по поповеру */
  cardList.addEventListener('click', function(event) {
    event.stopPropagation()
  })

  /* клик вне области поповера */
  function closebc() {
    cardList.classList.add('hidden')
    multiselectBtn.classList.remove('open')
    if (isListItem()) {
      inputMultiselect.classList.add('hidden')
    } else {
      inputMultiselect.classList.remove('small')
    }
  }

  function isListItem() {
    return multiselect.querySelector('.' + CLASS_LIST_ITEM)
  }

  document.addEventListener('click', closebc)

  /* Навешиваем focus на инпут */
  const inputChange = filterTagDataList => {
    if (filterTagDataList.length > 0 || !inputMultiselect.classList.contains('small')) {
      inputMultiselect.classList.add('small')
      inputMultiselect.focus()
    } else {
      inputMultiselect.classList.remove('small')
    }

    if (!filterTagDataList.length) {
      inputMultiselect.classList.remove('hidden')
    }
  }

  /* Событие ввода на input */
  inputMultiselect.addEventListener('input', function(e) {
    cardList.classList.remove('hidden')
    var val = e.target.value.toLowerCase()
    cardList.innerHTML = ''

    /* Актуальный массив инпутов */
    const inputHiddenListUpdate = multiselect.querySelectorAll('.multiselect-input-hidden')
    const tagDataUpdate = Array.prototype.slice.call(inputHiddenListUpdate)

    /* Актуальный список карточек */
    const cardListActual = initialcardListActual(tagDataUpdate)

    renderCardList(InputFilterData(val, cardListActual))
  })

  /* Фильтрация массива при вводе в input */
  function InputFilterData(val, data) {
    return data.filter(i => ~i.full_name.toLowerCase().indexOf(val))
  }
}


export default multiselectAuthor