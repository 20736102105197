import 'slick-carousel'

function initSliderMain() {
  const sliderTop = $('.slider-top')
  if (!sliderTop.length) {
    return
  }
  sliderTop.slick({
    infinite: false,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
    fade: true,
    dots: true,
  })

  document.addEventListener('turbolinks:before-cache', () => sliderTop.slick('unslick'))
}

function initSliderTvShows() {
  $('.responsive-slider').slick({
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 892,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  })
}
function initSliderPromo() {
  $('.slider-promo-bottom').slick({
    arrows: true,
    type: 'loop',
    autoplay: true,
    interval: 5000,
    slidesToShow: 2.1,
    slidesToScroll: 1,
    focus: 0,
    pagination: false,
    gap: 0,
    centerMode: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })
}

function initSliders() {
  initSliderMain()
  initSliderTvShows()
  initSliderPromo()
}

export default initSliders
