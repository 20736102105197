/* eslint no-console: 0 */

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import vSelect from 'vue-select'
import CompWrapper from '../src/CompWrapper.vue'
import HTTP from '../src/api/http-common'
import URL from '../src/api/url'

Vue.component('v-select', vSelect)
Vue.use(TurbolinksAdapter)
Vue.prototype.$HTTP = HTTP
Vue.prototype.$URL = URL

document.addEventListener('turbolinks:load', () => {
  function mountComponent(globalCompItem) {
    new Vue({
      components: { CompWrapper },
    }).$mount(globalCompItem)
  }

  const globalComp = Array.from(document.querySelectorAll('.global-comp'))
  if (!globalComp.length) {
    return
  }
  globalComp.forEach(globalCompItem => mountComponent(globalCompItem))
})
