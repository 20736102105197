import comments from './comments.js'
import goToComment from './goToComment.js'

const moreComments = () => {
  goToComment()
  $('body').on('click', '.add-comments-js', function() {
    $('.add-comments-js').on('ajax:success', function() {
      comments()
      goToComment()
    })
  })
}

export default moreComments