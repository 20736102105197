document.addEventListener('turbolinks:load', function() {
  $('.js-go-comments').click(function() {
    $('html,body').animate({ scrollTop: $('.js-row-comments').offset().top }, 1000)
  })

  $('.btn-result').click(function() {
    $('html,body').animate({ scrollTop: $('#filter-anchor').offset().top }, 1000)
  })
  $('.js-go-contest').click(function() {
    $('html,body').animate({ scrollTop: $('#contest').offset().top }, 1000)
  })
  // Кнопка на верх
  $('#back-top').hide()
  // fade in #back-top
  $(function() {
    $(window).scroll(function() {
      if ($(this).scrollTop() > $(window).height() + $(window).height() / 2) {
        $('#back-top').fadeIn()
      } else {
        $('#back-top').fadeOut()
      }
    })

    // scroll body to 0px on click
    $('#back-top a').click(function() {
      $('body,html').animate({
        scrollTop: 0,
      }, 800)
      return false
    })
  })
})